import "../styles/laws.scss";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
    return (
        <div className="container page law">
            <Helmet>
                <title>Datenschutzerklärung | coPetence GmbH</title>
            </Helmet>
            <h1>Privacy Policy</h1>
            <h2>Privacy Statement</h2>
            <h3>1. Privacy at a Glance</h3>
            <h4>General Information</h4>
            <p>The following notes provide a simple overview of what happens to your personal data when you visit our website. Personal data is all data with which you can be personally identified. Detailed information on the subject of data protection can be found in our privacy policy listed below this text.</p>
            <h4>Data collection on our website</h4>
            <strong>Who is responsible for the data collection on this website?</strong>
            <p>The data processing on this website is carried out by the website operator. You can find their contact details in the imprint of this website.</p>
            <br />
            <strong>How do we collect your data?</strong>
            <p>Your data are collected firstly by you providing it to us. This might be, for example, data you enter in a contact form.<br />Other data are automatically collected by our IT systems when you visit the website. These data are primarily technical data (e.g. internet browser, operating system or time of page view). The collection of this data is automatic as soon as you enter our website.</p>
            <br />
            <strong>What do we use your data for?</strong>
            <p>Part of the data is collected to ensure a proper functioning of the website. Other data can be used to analyze your user behavior.</p>
            <br />
            <strong>What rights do you have regarding your data?</strong>
            <p>You always have the right to receive information about the origin, recipient, and purpose of your stored personal data free of charge. You also have the right to request the correction, blocking, or deletion of this data. For this purpose, as well as for further questions on the subject of data protection, you can contact us at any time at the address given in the imprint. Furthermore, you have the right to file a complaint with the competent regulatory authority.</p>
            <h3>2. General Information and Mandatory Information</h3>
            <h4>Privacy</h4>
            <p>The operators of these pages take the protection of your personal data very seriously. We treat your personal data confidentially and in accordance with the statutory data protection regulations and this privacy policy.<br />When you use this website, various personal data are collected. Personal data is data with which you can be personally identified. This privacy policy explains which data we collect and what we use it for. It also explains how and for what purpose this happens.<br />We point out that data transmission over the Internet (e.g. communication by email) can have security gaps. A complete protection of data against access by third parties is not possible.</p>
            <h4>Note on the responsible entity</h4>
            <p>The responsible entity for data processing on this website is:<br />
                <br />
                <strong>coPetence GmbH</strong><br />
                <p>Parkstraße 30a</p>
                <p>DE-82065 Baierbrunn</p>
                <br />
                <p>Telephone: +49 151 68804370</p>
                <p>Email: info@copetence.de</p>
                <br />
                <p>The responsible entity is the natural or legal person who alone or jointly with others decides on the purposes and means of processing personal data (e.g. names, email addresses, etc.).</p>
            </p>
            <h4>Revocation of your consent to data processing</h4>
            <p>Many data processing operations are only possible with your express consent. You can revoke consent you have already given at any time. An informal email making this request is sufficient. The legality of the data processing carried out until the revocation remains unaffected by the revocation.</p>
            <h4>Right to file complaints with regulatory authorities</h4>
            <p>If there has been a breach of data protection legislation, the person affected may file a complaint with the competent regulatory authorities. The competent regulatory authority for matters related to data protection legislation is the data protection officer of the German state in which our company is headquartered. A list of data protection officers and their contact details can be found at the following <a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html">link</a>.</p>
            <h4>Right to data portability</h4>
            <p>You have the right to have data which we process based on your consent or in fulfillment of a contract automatically delivered to yourself or to a third party in a standard, machine-readable format. If you require the direct transfer of data to another responsible party, this will only be done to the extent technically feasible.</p>
            <h4>Information, blocking, deletion</h4>
            <p>Within the framework of the applicable statutory provisions, you have the right at any time to be provided free of charge information about your stored personal data, their source and recipients and the purpose of the data processing and, if necessary, a right to correct, block or delete this data. For further information on personal data you can contact us at any time at the address given in our imprint.</p>
            <h4>Opposition to promotional emails</h4>
            <p>We hereby expressly prohibit the use of contact data published in the context of website legal notice requirements with regard to sending promotional and informational materials not expressly requested. The website operator reserves the right to take specific legal action if unsolicited advertising material, such as email spam, is received.</p>
            <h3>3. Data collection on our website</h3>
            <h4>Server log files</h4>
            <p>The website provider automatically collects and stores information that your browser automatically transmits to us in "server log files". These are:</p>
            <br />
            <ul>
                <li>Browser type and browser version</li>
                <li>Operating system used</li>
                <li>Referrer URL</li>
                <li>Host name of the accessing computer</li>
                <li>Time of the server request</li>
                <li>IP address</li>
            </ul>
            <br />
            <p>No merging of this data with other data sources will be done.</p>
            <br />
            <p>The basis for data processing is Art. 6(1)(f) GDPR, which allows the processing of data to fulfill a contract or for measures preliminary to a contract.</p>
            <h3>4. Plugins and Tools</h3>
            <h4>Google Web Fonts</h4>
            <p>This site uses so-called web fonts, provided by Google, for the uniform representation of fonts. When you call up a page, your browser loads the required web fonts into your browser cache to display texts and fonts correctly.</p>
            <br />
            <p>For this purpose, the browser you are using must connect to Google's servers. As a result, Google becomes aware that our website has been accessed via your IP address. The use of Google Web Fonts is done in the interest of a uniform and attractive presentation of our online offers. This constitutes a justified interest pursuant to Art. 6(1)(f) GDPR.</p>
            <br />
            <p>If your browser does not support web fonts, a standard font is used by your computer.</p>
            <br />
            <p>Further information about Google Web Fonts can be found at <a href="https://developers.google.com/fonts/faq">https://developers.google.com/fonts/faq</a> and in Google's privacy policy: <a href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a>.</p>
        </div>
    );
}

export default PrivacyPolicy;
