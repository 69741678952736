import "./styles/header.scss";
import BGImage from "../../assets/landingHeaderBG.jpg"
import SLImage from "../../assets/landingHeaderSL.png"
import SPImage from "../../assets/landingHeaderSP.png"
import LPImage from "../../assets/landingHeaderLP.png"
import FlagGermany from "../../assets/germanyFlag.png"
import FlagEU from "../../assets/euFlag.png"
import Encrypted from "../../assets/encrypted.png"

const Header = () => {
    return (
        <div className="landingheader">
            <div className="topGrad"></div>
            <img src={BGImage} alt="" className="bg" />
            <div className="container">
                <div className="titles">
                    <h1>Künstliche Intelligenz -<br />Integriert in Ihr Geschäft</h1>
                    <h1 className="ss">Künstliche Intelligenz Integriert in Ihr Geschäft</h1>
                    <h1 className="sv">Künstliche (Geschäfts) Intelligenz</h1>
                    <h2>Wir haben uns darauf spezialisiert, KI-Modelle für jeden Ihrer Mitarbeiter verfügbar zu machen, bieten alle wichtigen Schnittstellen und entwickeln spezifische Integrationen.</h2>
                </div>
                <div className="feature-rows">
                    <FeatureRow icon={Encrypted} iconAlt="Sicherheit" text="Datensicherheit an 1. Stelle" />
                    <FeatureRow icon={FlagGermany} iconAlt="Deuschland" text="100% Deutsche Server" />
                    <FeatureRow icon={FlagEU} iconAlt="EU" text="DSGVO & GDPR" />
                </div>
                <img src={SLImage} alt="" className="headerImage sl" />
                <img src={LPImage} alt="" className="headerImage lp" />
                <img src={SPImage} alt="" className="headerImage sp" />
            </div>
        </div>
    )
}

const FeatureRow = ({
    icon, iconAlt, text
}: {
    icon: string;
    iconAlt: string;
    text: string;
}) => {
    return (
        <div className="feature-row">
            <div className="icon">
                <img src={icon} alt={iconAlt} />
            </div>
            <p className="tet">{text}</p>
        </div>
    )
}

export default Header;