import "../styles/laws.scss";
import { Helmet } from "react-helmet";

const Abuse = () => {
    return (
        <div className="container page law">
            <Helmet>
                <title>Missbrauch | coPetence GmbH</title>
            </Helmet>
            <h1>Missbrauch / Beschwerde</h1>
            <p>Wenn Sie eine Beschwerde einreichen oder uns auf einen Missbrauch aufmerksam machen wollen, wird der Prozess wie unten beschrieben ablaufen:</p>
            <h4>1. Beschwerde einreichen oder Missbrauch melden</h4>
            <p>Bitte geben Sie Ihren Namen, Ihre Adresse, Telefonnummer, E-Mail und eine Beschreibung des Problems, einschließlich des/der Domainnamen, an die unten angegebene Kontaktperson weiter.</p>
            <h4>Kontakt</h4>
            <p>E-Mail: <a href="mailto:abuse@copetence.de">abuse@copetence.de</a></p>
            <h4>2. Verarbeitung der Berichte</h4>
            <p>Unser Ziel ist es, jede einzelne Beschwerde oder Missbrauchsmeldung innerhalb von fünf Werktagen zu lösen. Wenn für die Lösung eines Problems mehr Zeit benötigt wird, werden Sie von unserem Support-Mitarbeiter über den voraussichtlichen Zeitrahmen informiert.</p>
            <h4>3. Wiedereröffnung eines Vorgangs</h4>
            <p>Um eine gelöste Beschwerde oder einen Missbrauchsbericht wieder zu öffnen, antworten Sie einfach mit zusätzlichen Informationen oder Details, die eine weitere Untersuchung des Problems ermöglichen.</p>
            <h4>Informationen zur TCO-Verordnung</h4>
            <p>Als Kontaktstelle gemäß Verordnung (EU) 2021/784 des Europäischen Parlaments und des Rates nutzen Sie bitte <a href="mailto:abuse-tco@copetence.de">abuse-tco@copetence.de</a>. Die Kontaktaufnahme ist in Deutsch und Englisch möglich.</p>
            <h4>EU-Streitschlichtung</h4>
            <p>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/">https://ec.europa.eu/consumers/odr/</a>.Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>
        </div>
    );
}

export default Abuse;