import React from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../assets/logo.svg";
import Menu from '../assets/icons/menu.svg';
import Twinkle from "../assets/aiTwinkle.svg";
import { config } from "../config";

const Navbar = () => {

    const links = [
        { name: "Home", path: "/" },
        // { name: "Success Stories", path: "/success_stories" },
        // { name: "About us", path: "/blog" },
        // { name: "Contact", path: "/contact" },
        // { name: "Kontakt", path: "/kontakt" },
    ]

    const nonTranslusentPages: string[] = []
    let location = useLocation();
    const [scrolled, setScrolled] = React.useState(false);
    const [expanded, setExpanded] = React.useState(false);

    React.useEffect(() => {
        setExpanded(false);
        window.scrollTo(0, 0);
        if (nonTranslusentPages.includes(window.location.pathname)) {
            setScrolled(true);
            document.onscroll = () => {}
        } else {
            setScrolled(false);

            document.onscroll = () => {
                if (window.scrollY > 20) {
                    setScrolled(true);
                } else {
                    setScrolled(false);
                }
            }
        }
       
    }, [location])

    return (
        <nav className={`navbar ${(scrolled || expanded) && "scrolled"}`} style={{
        }}>
            <div className="container">
                <div className="static">
                    <Link className="logo" to="/">
                        <img src={Logo} alt="coPetence Logo" />
                    </Link>
                    <button className="burgerMenu" onClick={() => setExpanded(state => !state)}>
                        <img src={Menu} alt="Menu" />
                    </button>
                </div>
                {/* <ul className={`links ${expanded && 'expanded'}`}>
                    {links.map(link =>
                        <li>
                            <Link to={link.path} className={location.pathname === link.path ? 'active' : ''}>{link.name}</Link>
                        </li>
                    )}
                </ul> */}
                <ul className={`links ${expanded && 'expanded'}`}>
                    <a href={config.bookingsURL} className="btnlink">
                        <img src={Twinkle} alt="" />
                        Erstgespräch vereinbaren
                    </a>
                </ul>
                

            </div>

        </nav>
    )
}

export default Navbar;