import Content from "../components/tech/Content";
import Header from "../components/tech/Header";
import "../styles/laws.scss";
import { Helmet } from "react-helmet";

const Tech = () => {
    return (
        <div className="container page">
            <Helmet>
                <title>Technische Details | coPetence GmbH</title>
            </Helmet>
            <Header />
            <Content />
        </div>
    );
}

export default Tech;