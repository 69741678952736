

import "./styles/headerinvite.scss"
import Calender from "../../assets/calendar.png"

const HeaderInvite = () => {
    return (
        <div className="headerinvite">
            <div className="box">
                <img className="cal" src={Calender} alt="Kalender 1." />
                <div className="content">
                    <h3>GenAI Focus Workshop</h3>
                    <p>
                        Nehmen Sie teil an unserem <strong>GenAI Focus Workshop</strong>! Am 01. August laden wir Sie herzlich zu unserem Focus Workshop ein. Aktuelles Thema: GenAI zur Optimierung von Geschäftsprozessen.
                    </p>
                    <a href="https://forms.gle/xBWkgNKxZNmMqfgd6" target="__blank">Jetzt Anmelden</a>
                </div>
            </div>
        </div>
    )
}

export default HeaderInvite;