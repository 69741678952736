import "./styles/plattform.scss";
import Pattern from "../../assets/pattern1.jpg";
import { Link } from "react-router-dom";

const Plattform = () => {
    return (
        <section className="plattform">
            <h2 className="sectionTitle">Unsere Plattform</h2>
            <div className="content">
                <div className="main">
                    <div className="bg">
                        <img src={Pattern} alt="" />
                    </div>
                    <div className="desc">
                        <h3>Künstliche<br />Intelligenz</h3>
                        <p>Wir stellen Ihren Mitarbeitern die weltweit anerkanntesten KI-Modelle zur Verfügung. Diese können die Produktivität in den unterschiedlichsten Anwendungen um ein Vielfaches steigern.</p>
                        → <Link to="/tech">Technische Details</Link>
                    </div>
                </div>
                <div className="features">
                    <div className="feature">
                        <h4 className="title">
                            Benutzeroberfläche
                        </h4>
                        <p className="text">
                            Unsere Benutzeroberfläche ist speziell auf die Bedürfnisse von Unternehmen zugeschnitten. Sie ist einfach zu bedienen und bietet alle wichtigen Funktionen.
                        </p>
                    </div>
                    <div className="feature">
                        <h4 className="title">
                            Vielseitige Schnittstellen
                        </h4>
                        <p className="text">
                            Unsere Plattform bietet alle wichtigen Schnittstellen, um KI-Modelle in Ihre bestehenden Systeme zu integrieren. Von Plugins für Office-Programme bis hin zu REST-APIs bieten wir alles.
                        </p>
                    </div>
                    <div className="feature">
                        <h4 className="title">
                            Speziefische Integrationen
                        </h4>
                        <p className="text">
                            Wir entwickeln spezifische Integrationen für Ihr Unternehmen. Unsere Experten beraten Sie gerne und finden die beste Lösung für Ihre Anforderungen.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Plattform;