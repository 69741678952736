import "./styles/content.scss";
import QualityIndex from "../../assets/llmQualityIndex.jpg";

const Content = () => {
    return (
        <div className="techcontent">
            <p>
                Die Bereitstellung von Large Language Models (LLMs) in der Cloud bietet enorme Vorteile, bringt aber auch einige Herausforderungen mit sich. Der immense Rechenbedarf dieser Modelle erfordert leistungsfähige GPUs und CPUs, die aufgrund der hohen Nachfrage und der aktuellen Chipknappheit nur schwer zu beschaffen sind. Insbesondere der Zugang zu modernen High-End-GPUs gestaltet sich schwierig. Derzeit setzen wir in unserer Produktionsumgebung NVIDIA-Grafikkarten der H100 und A100-Serie ein.
            </p>
            <h4>
                Die Wahl des richtigen LLM’s
            </h4>
            <p>
                Verschiedene Forschungsgruppen entwickeln unterschiedliche LLM mit jeweils eigenen Stärken und Schwächen. Daher ist es wichtig, die spezifischen Anforderungen der Endnutzer genau zu analysieren, um das geeignete Modell auszuwählen.<br /><br />Unsere Endnutzer werden mit Modellen der Llama 3-Serie bedient. Diese Modelle zeichnen sich durch eine hervorragende Leistung in verschiedenen Aufgabenbereichen aus. Im Vergleich zu ähnlichen Modellen, die unter einer Open Source Lizenz verfügbar sind, schneiden die Llama 3 Modelle sehr gut ab. Auch im Vergleich zu Closed-Source-Modellen schneiden diese Modelle in den meisten Anwendungen sehr gut ab.
            </p>
            <div className="splitcol">
                <p>
                    Die Qualität eines Large-Scale-Modells hängt vor allem von der Qualität der Antworten ab. Diese kann durch bestimmte Verfahren gemessen und ein sogenannter Qualitätsindex berechnet werden.<br /><br />Die Abbildung vergleicht die Qualität der bekanntesten LLMs. Dabei ist zu beachten, dass einige dieser Modelle nicht aus Open Source Projekten stammen. Zum Beispiel sind die beiden GPT-Modelle sowie Gemini und Claude nicht zugänglich.
                </p>
                <img style={{
                    width: "100%",
                    maxWidth: "600px",
                }} src={QualityIndex} alt="Statistik Qualitäts Index Large Langugage Modelle" />
            </div>
            <h4>
                Technische Herausforderungen
            </h4>
            <p>
                Eine der größten Herausforderungen besteht darin, die Modelle so zu skalieren, dass sie große Mengen an Anfragen gleichzeitig verarbeiten können. Dies erfordert eine effiziente Architektur, die es ermöglicht, die Rechenleistung der GPUs optimal zu nutzen.
            </p>
            <br />
            <p>
                Durch Technologien wie Continiuous Batching erreichen wir einen maximalen Durchsatz bei der Bearbeitung von Anfragen. Darüber hinaus ermöglichen wir durch einen 8-Bit-KV-Cache eine extrem hohe Kontextlänge, die es erlaubt, auch sehr lange Texte zu verarbeiten.
            </p>
        </div>
    )
}

export default Content;