import Integraions from "../../assets/integrations.png";
import "./styles/integrations.scss";

const Integrations = () => {
    return (
        <section className="integrations">
            <h2 className="sectionTitle">KI & Alles, was Sie dazu benötigen</h2>
            <p>Wir bieten Ihnen die fortschrittlichsten KI-Modelle auf dem Markt. In Kombination mit unserer intuitiven Benutzeroberfläche und zahlreichen intelligenten Integrationen beschleunigen wir Ihre Arbeit um ein Vielfaches.</p>
            <img src={Integraions} alt="Unsere Integrationen" />
        </section>
    );
}

export default Integrations;