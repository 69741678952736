import { Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Landing from './views/Landing';
import Imprint from './views/Imprint';
import NotFound from './views/404';
import PrivacyPolicy from './views/PrivacyPolicy';
import Tech from './views/Tech';
import Abuse from './views/Abuse';
import Security from './views/Security';

function App() {
  return (
    <div className="app">
      <Navbar />
      <div className="content">
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/datenschutz" element={<PrivacyPolicy />} />
          <Route path="/impressum" element={<Imprint />} />
          <Route path="/missbrauch" element={<Abuse />} />
          <Route path="/sicherheit" element={<Security />} />
          <Route path="/tech" element={<Tech />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
