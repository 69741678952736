import "./styles/header.scss";
import BGImage from "../../assets/landingHeaderBG.jpg"
import SLImage from "../../assets/techBanner.png"

const Header = () => {
    return (
        <div className="techheader">
            <img src={BGImage} alt="" className="bg" />
            <div className="container">
                <img src={SLImage} alt="" className="headerImage" />
                <div className="titles">
                    <h1>Blick hinter die Kulissen<br />Unser Ansatz</h1>
                </div>
            </div>
        </div>
    )
}

export default Header;