import "../styles/laws.scss";
import { Helmet } from "react-helmet";

const Security = () => {
    return (
        <div className="container page law">
            <Helmet>
                <title>Sicherheit | coPetence GmbH</title>
            </Helmet>
            <h1>Sicherheit</h1>
            <h3>Verantwortungsvolle Offenlegungspolitik</h3>
            <p>Wir nehmen die Sicherheit unserer Systeme sehr ernst. Die verantwortungsvolle Offenlegung von Sicherheitslücken hilft uns, die Sicherheit und den Schutz der Privatsphäre unserer Nutzer zu gewährleisten.</p>
            <h3>Richtlinien</h3>
            <p>Wir erwarten von allen Forschern:</p>
            <ul>
                <li>Alle Anstrengungen unternehmen, um Verletzungen der Privatsphäre, Beeinträchtigungen der Benutzerfreundlichkeit, Störungen der Produktionssysteme und Datenzerstörung während der Sicherheitstests zu vermeiden</li>
                <li>Forschung nur in dem unten aufgeführten Umfang durchzuführen</li>
                <li>die angegebenen Kommunikationskanäle zu nutzen, um uns Informationen über Sicherheitsschwachstellen zu melden und Informationen über entdeckte Sicherheitslücken vertraulich zwischen Ihnen und coPetence zu behandeln, bis wir 90 Tage Zeit haben, das Problem zu lösen. Wenn das Problem eine Bibliothek eines Drittanbieters, einschließlich Open-Source-Software, betrifft, bitten wir Sie, deren Offenlegungsrichtlinien zu befolgen.</li>
            </ul>
            <br />
            <p>Wenn Sie diese Richtlinien befolgen, wenn Sie ein Problem melden, werden wir uns daran halten:</p>
            <ul>
                <li>Wir werden keine rechtlichen Schritte im Zusammenhang mit Ihrer Forschung einleiten oder unterstützen</li>
                <li>mit Ihnen zusammenarbeiten, um das Problem schnell zu verstehen und zu lösen (einschließlich einer ersten Bestätigung Ihres Berichts innerhalb von 92 Stunden nach der Übermittlung)</li>
                <li>Wir nehmen Sie in unsere Hall of Fame für Sicherheitsforscher auf, wenn Sie das Problem als Erster melden und wir als Folge des Problems eine Code- oder Konfigurationsänderung vornehmen</li>
                <li>Zahlung als Zeichen unserer Wertschätzung für Ihre Bemühungen bei der Identifizierung und Meldung von Sicherheitslücken.</li>
            </ul>
            <h3>Umfang</h3>
            <ul>
                <li>Webseite (copetence.de)</li>
                <li>Chat Interface (chat.copetence.de)</li>
            </ul>
            <br />
            <p>Im Interesse der Sicherheit unserer Benutzer, Mitarbeiter, des Internets im Allgemeinen und Ihrer Sicherheit sind die folgenden Testarten vom Anwendungsbereich ausgeschlossen:</p>
            <ul>
                <li>Ergebnisse aus physischen Tests, wie z. B. Bürozugang (z. B. offene Türen, Hintereingang)</li>
                <li>Ergebnisse, die hauptsächlich auf Social Engineering beruhen (z. B. Phishing)</li>
                <li>Ergebnisse von Anwendungen oder Systemen, die nicht im Abschnitt „Umfang“ ausgeführt sind</li>
                <li>UI- und UX-Fehler und Rechtschreibfehler</li>
                <li>Netzwerkbezogene Denial-of-Service-Schwachstellen (DoS/DDoS)</li>
            </ul>
            <br />
            <p>Dinge, die wir nicht erhalten möchten:</p>
            <ul>
                <li>Persönlich identifizierbare Informationen (PII)</li>
            </ul>
            <h3>Wie melde ich eine Sicherheitslücke?</h3>
            <p>Wenn Sie vermuten, dass Sie eine Sicherheitslücke in einem unserer Produkte oder einer unserer Plattformen gefunden haben, senden Sie bitte eine E-Mail an <a href="mailto:security@copetence.de">security@copetence.de</a>. Bitte fügen Sie Ihrer Meldung die folgenden Details bei:</p>
            <ul>
                <li>Eine Beschreibung des Ortes und der möglichen Auswirkungen der Sicherheitslücke</li>
                <li>Eine detaillierte Beschreibung der Schritte, die erforderlich sind, um die Sicherheitslücke zu reproduzieren (POC-Skripte, Screenshots und komprimierte Bildschirmaufzeichnungen sind für uns sehr hilfreich)</li>
            </ul>
        </div>
    );
}

export default Security;