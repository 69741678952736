import { Helmet } from "react-helmet";
import '../styles/landing.scss';
import Header from '../components/landing/Header';
import DataFeatures from "../components/landing/DataFeatures";
import Integrations from "../components/landing/Integrations";
import Plattform from "../components/landing/Plattform";
import IntegrationStory from "../components/landing/IntegrationStory";
import HeaderInvite from "../components/landing/HeaderInvite";


const Landing = () => {
    return (
        <div className="landing">
            <Helmet>
                <title>coPetence | KI für Ihr Unternehmen</title>
            </Helmet>
            <div className="container">
                <Header />
                <HeaderInvite />
                <Integrations />
                <DataFeatures />
                <Plattform />
                {/* <IntegrationStory /> */}
            </div>
    
        </div>
    )
}

export default Landing;