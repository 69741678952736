import "./styles/datafeatures.scss"

const DataFeatures = () => {
    return (
        <section className="datafeatures">
            <h2 className="sectionTitle">
                Ihre Daten stehen an erster Stelle
            </h2>
            <div className="features">
                <FeatureRow title="Zertifizierte Partner" text="Wir arbeiten ausschließlich mit zertifizierten Partnern zusammen, die unsere Anforderungen an die Datensicherheit erfüllen." />
                <FeatureRow title="DSGVO Konform" text="Unser Angebot ist vollständig DSGVO-konform. Alle Anforderungen werden regelmäßig überprüft und verbessert." />
                <FeatureRow title="Deutsche Infrastruktur" text="Unsere Infrastruktur wird ausschließlich in Deutschland betrieben und unterliegt damit auch deutschen Sicherheits- und Datenschutzanforderungen." />
                <FeatureRow title="Keine Protokolle" text="Bei Bedarf garantieren wir, dass keine Nutzereingaben auf unserer Plattform protokolliert werden. Diese werden unmittelbar nach der Bearbeitung unwiderruflich aus unseren Systemen gelöscht." />
            </div>
        </section>
    )
}

const FeatureRow = ({
    title, text 
}: {
    title: string;
    text: string;
}) => {
    return (
        <div className="feature-row">
            <h3 className="title" dangerouslySetInnerHTML={{
                __html: title
            }} />
            <p className="text">{text}</p>
        </div>
    )
}

export default DataFeatures;