import React from "react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../assets/logo.svg";
import Twinkle from "../assets/aiTwinkle.svg";
import LinkedinIcon from "../assets/icons/linkedin.svg";
import { config } from "../config";

const Footer = () => {

    const noMarginPages = ['/', ]
    let location = useLocation();
    const [hasMargin, setHasMargin] = React.useState(true);

    React.useEffect(() => {
        if (noMarginPages.includes(window.location.pathname)) {
            setHasMargin(false);
        } else {
            setHasMargin(true);
        }
    }, [location])

    return (
        <footer className="footer" style={{
            marginTop: hasMargin ? "50px" : 0
        }}>
            <div className="container">
                <div className="row">
                    <div className="col">
                        <img src={Logo} alt="coPetence Logo" className="logo" />
                        <a className="book" href={config.bookingsURL}>
                            <img src={Twinkle} alt="" />
                            Erstgespräch vereinbaren
                        </a>
                    </div>
                    <div className="links">
                        <div className="linkcol">
                            <p className="coltitle">
                                Unternehmen
                            </p>   
                            <Link to="/impressum">Impressum</Link>
                            <Link to="/datenschutz">Datenschutz</Link>
                            <Link to="/missbrauch">Missbrauch</Link>
                        </div>
                        <div className="linkcol">
                            <p className="coltitle">
                                Systeme
                            </p>
                            <Link to="/tech">Unsere Technik</Link>    
                            <a href="https://status.copetence.de" target="__blank">System Status</a>
                            <Link to="/sicherheit">Sicherheit</Link>
                        </div>
                        <div className="linkcol">
                            <p className="coltitle">
                                Kontakt
                            </p>
                            <a href="mailto:info@copetence.de">
                                info@copetence.de
                            </a>    
                            <p>+49 15168804370</p>
                        </div>
                    </div>
                </div>
                <div className="row light">
                    <p>Copyright © {new Date(Date.now()).getFullYear()} coPetence GmbH</p>
                    <p>Parkstr. 30a, 82065 Baierbunn, Deutschland</p>
                </div>
            </div>
        </footer>
    )
}

export default Footer;